<template>
    <layout-page :classes="pageClasses" v-slot:default="slotProps">
      <section v-if="dataErrored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
    <section v-else>
      <loading v-if="dataLoading"></loading>
       <template v-else>
      <div class="container-fluid bg__secondary cover">
        <video v-if="page[0].acf.video_background" muted  autoplay id="video_bg" loop="loop" playsinline :poster="page[0].fimg_url.full">
               <source :src="page[0].acf.video_background.video_mp4" type="video/mp4">
               <source :src="page[0].acf.video_background.video_webm" type="video/webm">
        </video>
        <div class="row">
          <div class="col-12 col-lg-6 bg__secondary">
            <div class="row">
              <div class="col-12 col-lg-10 offset-lg-2">
                <h1 class="text-uppercase tx__w-bold" v-html="renderTitle(page[0].title.rendered)"></h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid bg__secondary py-4 py-lg-5 position-relative">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <div class="row">
              <div class="col-12 col-lg-6">
                           <div class="w-100" v-html="page[0].content.rendered"></div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid bg__secondary py-3 py-lg-5">
          <div class="row py-lg-5">
            <div class="col-12">
              <div class="row justify-content-end align-items-center">
                <div class="col-12 col-lg-4 bg__secondary py-lg-5 z-index-5 mn-r-8">
                  <div class="w-100 px-5 py-4">
                    <h2 class="text-uppercase tx__w-bold mx-auto text-center pb-4" v-html="renderTitle(page[0].acf.title_1)">
                    </h2>
                    <div class="w-100" v-html="page[0].acf.copy_1"></div>
                    <div class="w-100 py-3 text-center" v-html="slotProps.options.address">
                    </div>
                    <a :href="'mailto:'+slotProps.options.email" class="d-block link link--white text-center pb-3" target="_blank">{{slotProps.options.email}}</a>
                    <a :href="'tel:'+slotProps.options.phone" class="d-block link link--white text-center">{{slotProps.options.phone}}</a>
                  </div>
                </div>
                <div class="col-12 col-lg-8 ">
                  <figure class="figure figure__map">
                    <Map />
                    <span class="stroke stroke-top-left"></span>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid bg__secondary py-5">
          <div class="row justify-content-center position-relative pb-5 mb-5">
            <div class="col-12 col-lg-10 z-index-2">
              <div class="row position-relative bg__black z-index-2 py-5">
                <div class="col-12 col-lg-6 py-5">
                  <div class="w-100 p-3">
                  <h2 class="text-uppercase tx__w-bold mx-auto pb-4 text-center text-lg-left" v-html="renderTitle(page[0].acf.title_2)">
                    </h2>
                    <div class="w-100" v-html="page[0].acf.copy_2"></div>
                  </div>
                    <contact-form></contact-form>
                </div>
                <div class="col-12 col-lg-6 py-5">
                  <div class="w-100 p-3">
                  <h2 class="text-uppercase tx__w-bold mx-auto pb-4 text-center text-lg-left" v-html="renderTitle(page[0].acf.title_3)">
                    </h2>
                    <div class="w-100" v-html="page[0].acf.copy_3"></div>

                  </div>
                    <newsletter-form></newsletter-form>
                </div>
              </div>
              <span class="stroke stroke-bottom-right"></span>
            </div>
          </div>
        </div>
        </template>
    </section>
    </layout-page>
</template>

<script>
import LayoutPage from "@/layouts/LayoutPage.vue";
import ContactForm from "@/components/contact/ContactForm.vue";
import NewsletterForm from "@/components/contact/NewsletterForm.vue";
import Loading from '@/components/Loading.vue';
import Map from "@/components/contact/Map.vue";
export default {
name: "Contact",
  components: {
    LayoutPage,ContactForm, NewsletterForm, Map, Loading
  },
  data() {
    return {
      fetchSlug: String,
      itLoads: false,
      dataLoading: true,
      dataErrored: false,
      page: [
        {
          title:{
            rendered: this.$route.name
          }
        }
      ],
      refe: Array,
      pageTitle: Array,
      pageClasses: ["page-contact"],
      markers: {
        0: {
          full_name: 'Erich  Kunze',
          position: {
            lat: -33.8627945,
            lng: 151.1935043
          }
        },
        1: {
          full_name: 'Delmer Olson',
          position: {
            lat: 25,
            lng: 123.89
          }
        }
      },
      
    }
  },
  head() {
    return {
      title: this.page[0]._yoast_wpseo_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.page[0]._yoast_wpseo_metadesc,
        },
      ],
    };
  },
  methods: {
    splitString(stg){
          let str = stg;
          let splStrt = str.split(" ");
          return splStrt;
      },
      fetchingData() {
        this.fetchSlug = this.$route.path;
        this.$axios
          .get( this.$axios.defaults.baseURL +'wp/v2/pages/?slug=' +this.fetchSlug )
          .then((response) => {
            this.page = response.data;
            this.itLoads = true
            //this.renderTitle(this.page[0].title.rendered);
          })
         .catch((error) => {
            console.log(error);
             this.dataErrored = true
          }).finally(() => this.dataLoading = false);
      },
      renderTitle(stg){
        let inputstg = this.splitString(stg);
        inputstg[0] = '<span class="tx__c-primary">'+inputstg[0]+'</span>';
        inputstg = inputstg.join(' ');
        return inputstg;
      }
      
  },
  created() {
    this.fetchingData();
  },
}
</script>

<style>

</style>