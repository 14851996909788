<template>
  <div class="contact-form p-3">
    <b-form @submit="onSubmit">
      <b-form-group class="my-2 pb-4" id="input-group-2">
        <b-form-input id="contact-form-name" v-model="form.name" required placeholder="Name*"></b-form-input>
      </b-form-group>

      <b-form-group class="my-2 pb-4" id="input-group-1">
        <b-form-input
          id="contact-form-email"
          v-model="form.email"
          type="email"
          required
          placeholder="Email*"
        ></b-form-input>
      </b-form-group>

      <b-form-group class="my-2 pb-4" id="input-group-2">
        <b-form-input id="contact-form-company" v-model="form.company" placeholder="Company"></b-form-input>
      </b-form-group>

      <b-form-group class="my-2 pb-4" id="input-group-3">
        <b-form-textarea
          id="contact-form-message"
          v-model="form.message"
          placeholder="Message*"
          rows="3"
          max-rows="6"
          required
        ></b-form-textarea>
      </b-form-group>

      <b-button type="submit" class="bt bt__ghost bt__primary mx-auto mx-lg-0">Send</b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        company: "",
        message: ""
      },
    };
  },
  methods: {
    sendForm() {
      const formData = new FormData();
      formData.append("your-subject", 'Contac from Vue');
      formData.append("your-name", this.form.name);
      formData.append("your-email", this.form.email);
      formData.append("your-company", this.form.company);
      formData.append("your-message", this.form.message);
      this.$axios
        .post("contact-form-7/v1/contact-forms/148/feedback", formData)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.sendForm();
      alert("Thanks for your message");
    }
  }
};
</script>


