<template>
  <div class="newsletter-form p-3">
    <!-- Begin Mailchimp Signup Form -->
<div id="mc_embed_signup">
<form action="https://hourigan-international.us7.list-manage.com/subscribe/post?u=400562b00ee9bfdc33e3b0e51&amp;id=56636df6bd" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">

<fieldset class="form-group my-2 pb-4">
<div class="mc-field-group">
	<input type="email" value="" name="EMAIL" class="required email form-control" placeholder="Email*" required id="mce-EMAIL">
</div>

</fieldset>

<fieldset class="form-group my-2 pb-4">
<div class="mc-field-group">
	<input type="text" value="" name="FNAME" class="form-control" placeholder="First name"  id="mce-FNAME">
</div>
</fieldset>

<fieldset class="form-group my-2 pb-4">
<div class="mc-field-group">
	<input type="text" value="" name="LNAME" class="form-control" placeholder="Last name" id="mce-LNAME">
</div>
</fieldset>

<fieldset class="form-group my-2 pb-4">
<div class="mc-field-group">
	<input type="text" value="" name="COMPANY" class="form-control" placeholder="Company" id="mce-COMPANY">
</div>

</fieldset>
	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_400562b00ee9bfdc33e3b0e51_56636df6bd" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="bt bt__primary bt__ghost button"></div>
    </div>
</form>
</div>

<!--End mc_embed_signup-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        company: ""
      },
    };
  },
  methods: {
  }
};
</script>


